const severityOptions = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
};

const setAlertError = (state, message) => state.alert = {
    open: true,
    message: message,
    severity: severityOptions.error,
};

const setAlertSuccess = (state, message) => state.alert = {
    open: true,
    message: message,
    severity: severityOptions.success,
};

const alertInitialState = {
    open: false,
    message: '',
    severity: severityOptions.success,
};


const trimActionType = (type) => type?.split(/pending|fulfilled|rejected/g)[0];

export const authUtils = {
    alertInitialState,
    setAlertError,
    setAlertSuccess,
    trimActionType,
};
