
import { lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { NotFound } from 'fni-components-next';
import Authentication from './components/Authentication';
import ChangePasswordPage from './components/ChangePasswordPage';
import ForceChangePasswordPage from './components/ForceChangePasswordPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import LoginPage from './components/LoginPage';
import { authSelectors } from './modules/auth';
import { coreSelectors } from '../redux/CoreSlice/core';

const LogoutPage = lazy(() => import('./components/LogoutPage'));
const MfaEnrollmentPage = lazy(() => import('./components/MfaEnrollmentPage'));

const RequireAuth = ({ children, redirectTo, authenticated }) => authenticated ? children : <Navigate to={redirectTo} />;

export default function AuthenticationRouter() {
    const currentAuthenticatedUser = useSelector(authSelectors.currentAuthenticatedUser);
    const tenantCode = useSelector(coreSelectors.tenantCode);

    const { pathname, state } = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        if(tenantCode && (pathname === '/auth/login' || pathname === '/auth/login/')) navigate('/auth/login/' + tenantCode);
    }, [tenantCode, pathname]);

    return (
        <Routes>
            <Route path='/' element={<Authentication />}>
                <Route path='login/:code' element={<LoginPage /> } />

                <Route path='forgot-password' element={<ForgotPasswordPage /> } />
                
                <Route path='change-password' element={
                    <RequireAuth authenticated={currentAuthenticatedUser} redirectTo={'/auth/login/' + tenantCode}>
                        <ChangePasswordPage />
                    </RequireAuth>
                } />

                <Route path='force-change-password' element={
                    <RequireAuth authenticated={currentAuthenticatedUser} redirectTo={'/auth/login/' + tenantCode}>
                        <ForceChangePasswordPage oldPassword={state?.oldPassword || ''} />
                    </RequireAuth>
                } />

                <Route path='logout' element={
                    <LogoutPage />                    
                } />

                <Route path='register' element={
                    <RequireAuth authenticated={currentAuthenticatedUser} redirectTo={'/auth/login/' + tenantCode}>
                        <MfaEnrollmentPage />
                    </RequireAuth>
                } />

                <Route path='*' element={<NotFound />} />
            </Route>
        </Routes>
    );
}
