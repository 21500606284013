import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import { SimpleNavBar, LoadingSpinner, NotFound } from 'fni-components-next';
import { authSelectors, authActions } from '../authentication/modules/auth';
import { coreActions, coreSelectors } from '../redux/CoreSlice/core';
import AuthenticationRouter from '../authentication/AuthenticationRouter';
import allowedUnauthenticatedRoutes from './allowedUnauthRoutes';

const TenantsRouter = lazy(() => import('../tenant-admin/TenantsRouter.js'));
const UsersRouter = lazy(() => import('../user-admin/UsersRouter.js'));

export default function UnmigratedRouter() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentAuthenticatedUser = useSelector(authSelectors.currentAuthenticatedUser);
    const currentSelectedTenant = useSelector(authSelectors.currentSelectedTenant);
    const tenantCode = useSelector(coreSelectors.tenantCode);
    const navLinks = useSelector(coreSelectors.navLinks);
    const { pathname } = useLocation();


    const handleSignOut = () => dispatch(authActions.signOutUser())
        .then(response => navigate('/auth/login/' + response?.payload));

    useEffect(() => {
        if(currentAuthenticatedUser && currentSelectedTenant) dispatch(coreActions.getNavLinks());
    }, [currentAuthenticatedUser, currentSelectedTenant]);


    useEffect(() =>{
        if(currentAuthenticatedUser?.isFNIAdmin && pathname.includes('/users') && !pathname.includes('tenants')) navigate('/tenants/list/users');
    }, [currentAuthenticatedUser]);

    useEffect(() => {
        if(!currentAuthenticatedUser && !allowedUnauthenticatedRoutes.includes(pathname)) navigate('/auth/login/' + tenantCode);
    }, [pathname]);


    return (
        <>
            <CssBaseline />
            <SimpleNavBar 
                currentAuthenticatedUser={currentAuthenticatedUser} onSignOut={handleSignOut} 
                code={tenantCode}
                links={navLinks} />
            <Suspense fallback={ <LoadingSpinner open={true} /> }>
                <Routes>
                    { currentAuthenticatedUser && !currentAuthenticatedUser ?.isFNIAdmin ? <Route path='users/*' element={<UsersRouter />} /> : null }
                    { currentAuthenticatedUser?.isFNIAdmin ? <Route path='tenants/*' element={<TenantsRouter />} /> : null }
                    <Route path='auth/*' element={<AuthenticationRouter />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
        </>
    );
}
