import { createSlice, createAsyncThunk, createSelector, miniSerializeError } from '@reduxjs/toolkit';
import { 
    getAuth,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from  'firebase/functions';
import axios from 'axios';
import { authUtils } from '../utils';

// SELECTORS-----------------------------------------
const currentAuthenticatedUser = createSelector(
    state => state.auth.user,
    user => user?.email ? user : null,
);

const currentSelectedTenant = createSelector(
    state => state.auth.currentSelectedTenant,
    tenant => tenant,
);

const availableTenants = createSelector(
    state => state.auth.user.tenants,
    tenants => tenants?.length ? tenants : null,
);

const authLoading = createSelector(
    state => state.auth.isLoading,
    isLoading => Object.values(isLoading).some(val => val),
);

const isFNIAdmin = createSelector(
    state => state.auth?.user?.isFNIAdmin,
    isAdmin => isAdmin,
);

const authAlert = createSelector(
    state => state.auth.alert,
    alert => alert,
);

const isUserRegistered = createSelector(
    state => state.auth.userIsRegistered,
    isRegistered => isRegistered,
);

const tenantIsMigrated = createSelector(
    state => state.auth.tenantIsMigrated,
    isMigrated => isMigrated,
);


export const authSelectors = {
    authLoading,
    authAlert,
    availableTenants,
    isFNIAdmin,
    currentAuthenticatedUser, 
    currentSelectedTenant,
    isUserRegistered,
    tenantIsMigrated,
};

// ACTIONS --------------------------------------------
const forceChangePassword = createAsyncThunk(
    'auth/disableForceChangePassword',
    async (passwords) => {
        const functions = getFunctions();
        const forceChangePwdFunction = httpsCallable(functions, 'auth-forceChangePassword');
        return await forceChangePwdFunction(passwords);
    },
);

const getEnrollMfaCode = createAsyncThunk(
    'auth/getEnrollMfaCode',
    async () => {
        const functions = getFunctions();
        const handleGetEnrollMfaCodeFunction = httpsCallable(functions, 'auth-getEnrollMFACode');
        return await handleGetEnrollMfaCodeFunction();
    },
);

const getRedirect = createAsyncThunk(
    'auth/getRedirect',
    async (tenantCode) => {
        const functions = getFunctions();
        const getRedirectFunction = httpsCallable(functions, 'auth-getRedirect');
        return await getRedirectFunction(tenantCode);
    },
);

const getCurrentTenantByCode = createAsyncThunk(
    'auth/getCurrentTenantByCode',
    async (_, { getState }) => {
        const code = getState().core.tenantCode;
        const functions = getFunctions();
        const getCurrentTenantByCodeFunction = httpsCallable(functions, 'tenants-getTenantByCode');
        return await getCurrentTenantByCodeFunction(code);
    },
);

const handleInvite = createAsyncThunk(
    'auth/handleInvite',
    async (inviteId) => {
        const functions = getFunctions();
        const handleInviteFunction = httpsCallable(functions, 'users-handleUserInvite');
        return await handleInviteFunction(inviteId);
    },
);

const signInUser = createAsyncThunk(
    'auth/signInUser',
    async (creds) => {
        const url = '/loginWithCredentials';
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        return await axios.post(url, creds, { withCredentials : true });
    },
    { 
        serializeError: (payload) => {

            var customError = payload.response.data;
            if (customError.message === 'MFA_NOT_ENABLED' ||
                customError.message === 'FORCE_CHANGE_PASSWORD') {
                    
                return {
                    code: customError.code,
                    message: customError.message,
                    token: customError.details.token
                };
            } else {
                return miniSerializeError(customError);
            }
        }
    }
);

const signOutUser = createAsyncThunk(
    'auth/signOutUser',
    async (_, { dispatch, getState }) => {
        const code = getState().core?.tenantCode;
        await signOut(getAuth());
        dispatch(authActions.logout());
        return code;
    },
);

const sendResetEmail = createAsyncThunk(
    'auth/sendResetEmail',
    async ({ email }) => await sendPasswordResetEmail(getAuth(), email),
);

const verifyMFA = createAsyncThunk(
    'auth/verifyMFA',
    async (code) => {
        const functions = getFunctions();
        const verifyMFAFunction = httpsCallable(functions, 'auth-verifyMFA');
        return await verifyMFAFunction(code);
    },
);

const { 
    alertInitialState, 
    setAlertError, 
    setAlertSuccess, 
    trimActionType, 
} = authUtils; 

const initialState = {
    user: {
        id: null,
        email: null,
        userId: null,
        tenantRoles: [],
        isFNIAdmin: null,
    },
    currentSelectedTenant: null,
    tenantIsMigrated: false,
    userIsRegistered: false,
    unregisteredUser: null,
    isLoading: {},
    alert: alertInitialState,
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser(state, action) {
            state.user = action.payload;
        },
        clearAuth(state) {
            return initialState;
        },
        setSuccessMessage(state, action) {
            setAlertSuccess(state, action.payload);
        },
        setCurrentTenant(state, action) {
            state.currentSelectedTenant = action.payload;
        },
        setErrorMessage(state, action) {
            setAlertError(state, action.payload);
        },
        setTenantIsMigrated(state, action) {
            state.tenantIsMigrated = action.payload;
        },
        setUserIsRegistered(state, action) {
            state.userIsRegistered = action.payload;
        },
        hideAlert(state) {
            state.alert.open = false;
        },
        logout() {
            //check in store.js to see state reset for logout
        },
    },
    extraReducers: {
        [forceChangePassword.fulfilled]: (state, action) => {
            setAlertSuccess(state, 'Password changed!');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [forceChangePassword.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [forceChangePassword.rejected]: (state, action) => {
            setAlertError(state, 'Unable to change password');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getCurrentTenantByCode.fulfilled]: (state, action) => {
            state.currentSelectedTenant = action.payload.data.tenantId;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getCurrentTenantByCode.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getCurrentTenantByCode.rejected]: (state, action) => {
            setAlertError(state, 'Unable to get selected tenant');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getEnrollMfaCode.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getEnrollMfaCode.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getEnrollMfaCode.rejected]: (state, action) => {
            setAlertError(state, 'Unable to get MFA enrollment code');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getRedirect.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getRedirect.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getRedirect.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [handleInvite.fulfilled]: (state, action) => {
            state.unregisteredUser = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [handleInvite.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [handleInvite.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [sendResetEmail.fulfilled]: (state, action) => {
            setAlertSuccess(state, 'Email sent!');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [sendResetEmail.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [sendResetEmail.rejected]: (state, action) => {
            setAlertError(state, 'Unable to send email');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [signInUser.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [signInUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [signInUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [signOutUser.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [signOutUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [signOutUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [verifyMFA.fulfilled]: (state, action) => {
            const result = action.payload?.data;
            if (!result?.success) setAlertError(state, 'Invalid MFA code, please retry');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [verifyMFA.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [verifyMFA.rejected]: (state, action) => {
            setAlertError(state, 'Unable to verify MFA');
            state.isLoading[trimActionType(action.type)] = false;
        },
    },
});

export const authActions = {
    ...AuthSlice.actions,
    forceChangePassword,
    getCurrentTenantByCode,
    getEnrollMfaCode,
    getRedirect,
    handleInvite,
    signInUser,
    signOutUser,
    sendResetEmail,
    verifyMFA,
};

export default AuthSlice.reducer;
